<template>
  <div
    ref="card"
    v-resize="updateHeight"
  >
    <ContextBoxLoader
      v-if="!visible"
      :content-height="width"
      :kpi-count="kpis.length"
    />
    <article
      v-else
      class="context-box"
    >
      <header>
        <div class="top">
          <div class="left">
            <v-icon
              color="shamrock"
              class="mr-2"
            >
              {{ icon }}
            </v-icon>
            <span class="headline shamrock--text">
              {{ title }}
            </span>
          </div>
          <div class="right display-2 font-weight-light">
            {{ total }}
          </div>
        </div>
        <div class="subtitle-2 mt-2">
          <slot name="subtitle">
            {{ subtitle }}
          </slot>
        </div>
      </header>
      <main
        :style="{ height: `${width}px` }"
      >
        <slot />
        <canvas
          v-if="chartOptions"
          ref="canvas"
          class="chart"
          height="200"
          width="350"
        />
      </main>
      <footer>
        <div class="kpis">
          <div
            v-for="(kpi, index) in kpis"
            :key="index"
            class="kpi"
            :class="index === 0 ? 'shamrock--text' : ''"
          >
            <span class="display-1">
              {{ kpi.value }}
            </span>
            <span class="body-2">
              {{ kpi.title }}
            </span>
          </div>
        </div>
      </footer>
    </article>
  </div>
</template>

<script>
import Chart from 'chart.js'
import ContextBoxLoader from './ContextBoxLoader'

export default {
  name: 'ContentBox',
  components: {
    ContextBoxLoader
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    total: {
      type: Number,
      default: null
    },
    kpis: {
      type: Array,
      default: null
    },
    chartOptions: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null,
      width: 500
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler (visible) {
        if (visible && this.chartOptions) {
          this.$nextTick(() => {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext('2d')
            this.chart = new Chart(ctx, this.chartOptions)
          })
        }
      }
    }
  },
  methods: {
    updateHeight () {
      this.width = this.$refs.card.getBoundingClientRect().width * 0.7
    }
  }
}
</script>

<style lang="scss" scoped>
  .context-box {
    background: #fff;
    width: 100%;
    header {
      padding: 20px 17px;
      .top {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
        }
        .right {
          margin-right: 10px;
        }
      }
    }
    main {
      height: 270px;
      padding: 0 10px;
    }
    footer {
      padding: 26px 5px;
      background: var(--v-leaf-base);
      border-top: 1px solid var(--v-leaf-darken1);
      .kpis {
        display: flex;
        .kpi {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 33.3%;
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
