<template>
  <div
    class="white context-box-loader"
  >
    <div class="d-flex align-center justify-space-between pl-5 pr-8 pt-7 pb-4">
      <v-skeleton-loader
        type="heading"
        width="190"
      />
      <v-skeleton-loader
        type="button"
      />
    </div>
    <v-skeleton-loader
      type="text"
      class="mx-5 pb-5"
      width="300"
    />
    <v-skeleton-loader
      type="image"
      :height="contentHeight"
      tile
    />
    <div
      class="d-flex align-center justify-space-between px-7 py-7"
      style="height: 113px;"
    >
      <div
        v-for="n in 3"
        :key="n"
        :style="{ opacity: n > kpiCount ? 0 : 1 }"
      >
        <v-skeleton-loader
          type="chip"
          height="43"
        />
        <v-skeleton-loader
          type="text"
          height="12"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextBoxLoader',
  props: {
    contentHeight: {
      type: Number,
      required: true
    },
    kpiCount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .context-box-loader {
    .v-skeleton-loader__heading {
      width: 100%;
    }
    .v-skeleton-loader__image {
      height: 100%;
    }
  }
</style>
