var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sliced-overview-stats"},[(!_vm.totalTrees)?_c('div',{staticClass:"white d-flex align-center px-5 py-3 mb-4"},[_c('v-skeleton-loader',{staticClass:"mr-5 mt-1",attrs:{"type":"text","width":"150","height":"15"}}),_c('v-skeleton-loader',{attrs:{"type":"chip","width":"140"}})],1):_c('div',{staticClass:"white shamrock--text px-5 py-2 mb-4 d-flex align-center body-1"},[_vm._v(" Total trees planted:"),_c('span',{staticClass:"display-1 mx-3"},[_vm._v(_vm._s(_vm.totalTrees.toLocaleString()))])]),_c('div',{staticClass:"white"},[_c('div',{staticClass:"title px-5 pt-5 pb-2"},[_vm._v(" Stats overview ")]),_c('div',{staticClass:"subtitle-2 px-5 pb-3"},[_vm._v(" Excludes student projects and projects with onboarding data only ")]),_c('v-select',{staticClass:"mx-6 mt-2 mb-2",attrs:{"label":"Showing data for","hide-details":"","items":[
        { text: 'All projects', value: 'All projects' },
        { text: 'Parks, residential, on-structure, mixed-use, campus developments', value: 'not(Streetscapes & plazas)' },
        { text: 'Streetscapes and plazas', value: 'Streetscapes & plazas' }
      ]},model:{value:(_vm.projectGrouping),callback:function ($$v) {_vm.projectGrouping=$$v},expression:"projectGrouping"}}),_vm._l((_vm.displayedStats),function(ref,index){
      var text = ref.text;
      var value = ref.value;
      var units = ref.units;
      var tooltipText = ref.tooltipText;
return _c('v-banner',{key:index,staticClass:"px-0",staticStyle:{"height":"65px","box-sizing":"border-box"},style:({ 'border-left': ("10px solid var(--v-" + ([
                  'metal',
                  'metal',
                  'accent',
                  'accent',
                  'north'
                ][index]) + "-lighten1)"),
                'margin-top': index === 0 ? '22px' : 0,
                'background-color': (text === 'Mean YTP' && _vm.includedFirstVersion) ? '#E0E0E0' : 'white' })},[(!value)?_c('v-skeleton-loader',{staticStyle:{"margin":"9px 20px 9px 0"},attrs:{"type":"text","height":"14"}}):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"fit-content"}},tooltipText ? on : undefined),[_c('span',{staticClass:"body-2",staticStyle:{"font-size":"0.8em !important"}},[_vm._v(_vm._s(text)+":")]),_c('span',{staticClass:"mx-2 px-0",class:[
                'metal--text',
                'metal--text',
                'accent--text',
                'accent--text',
                'shamrock--text' ][index]},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s((text === 'Mean YTP' && _vm.includedFirstVersion) ? '-' : _vm.formatOverviewStatValue(value)))]),_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s((text === 'Mean YTP' && _vm.includedFirstVersion) ? '' : units))])])])]}}],null,true)},[_vm._v(" "+_vm._s(tooltipText)+" ")])],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }