<template>
  <v-card
    class="mx-3"
    elevation="0"
  >
    <v-sheet
      style="overflow: auto;"
      class="shamrock pa-4"
      elevation="0"
    >
      <div class="headline white--text mb-2">
        Explore projects
      </div>
      <div class="subtitle-2 white--text">
        Search for projects by specifying one or more fields below
      </div>
      <v-form
        style="width: 100%;"
        @submit.prevent="loadResults"
      >
        <v-row class="align-center">
          <v-col cols="2">
            <v-text-field
              v-model="query['user.email']"
              label="User email"
              dark
              flat
              solo-inverted
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="query['user.name']"
              label="User name"
              dark
              flat
              solo-inverted
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="query['user.company']"
              label="Company name"
              dark
              flat
              solo-inverted
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="query.title"
              label="Project title"
              dark
              flat
              solo-inverted
              hide-details
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              type="submit"
              color="white"
              large
              elevation="0"
              :loading="loading"
            >
              Load results
              <v-icon right>
                mdi-magnify
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              type="button"
              large
              color="leaf"
              elevation="0"
              outlined
              @click="searchMyUser"
            >
              Show my projects
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-sheet
        v-if="projects.length > 0"
        style="max-height: 400px; overflow: auto; border-radius: 4px;"
      >
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="project in projects"
              :key="project._id"
              two-line
              :disabled="!project.activeVersion"
              @click="project.activeVersion ? downloadSpreadsheet(project.activeVersion._id, project.title) : undefined"
            >
              <v-list-item-content>
                <v-list-item-title>{{ project.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ project.user.name }} /
                  {{ project.user.company }} /
                  {{ (new Date(project.creationDate)).toISOString().slice(0,10) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <span
                style="white-space: nowrap;"
                class="caption"
                :class="project.activeVersion ? 'shamrock--text' : 'metal--text'"
              >
                {{ project.activeVersion ? 'Click to download spreadsheet for active version' : 'No active version' }}
              </span>
            </v-list-item>
          </v-list-item-group>
          <v-list-item
            class="justify-center body-2 metal--text"
          >
            {{ projects.length === 20 ? 'Showing first 20 projects matching query' : `${projects.length} projects found matching query` }}
          </v-list-item>
        </v-list>
      </v-sheet>
      <!-- <v-treeview
        ref="tree_view"
        :items="items"
        :load-children="fetchChildItem"
        activatable
      >
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item._level === 'user'">
            mdi-account
          </v-icon>
          <v-icon v-else-if="item._level === 'project'">
            mdi-pine-tree
          </v-icon>
          <v-icon v-else>
            mdi-file-document-edit
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <div class="d-flex align-center">
            <strong class="mr-2">{{ item.name || item.title || item.id }}</strong>
            <span
              v-if="item._level === 'user'"
              class="mr-2"
            >{{ item.email }}</span>
            <span v-if="item._level === 'version'">
              {{ (new Date(item.creationDate)).toISOString().slice(0,10) }}
            </span>
            <v-btn
              v-if="item._level === 'version'"
              outlined
              class="ml-4"
              color="white"
              :disabled="downloaded"
              :loading="downloading"
              @click="downloadSpreadsheet(item.id, item.title)"
            >
              {{ downloaded ? 'Downloaded' : 'Download spreadsheet' }}
            </v-btn>
          </div>
        </template>
      </v-treeview> -->
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: 'UserExplorer',
  data () {
    return {
      items: [],
      search: '',
      loading: false,
      projects: [],
      downloaded: false,
      downloading: false,
      timeout: null,
      query: {}
    }
  },
  watch: {
    downloading () {
      clearTimeout(this.timeout)
    },
    downloaded (val) {
      if (val) {
        this.timeout = setTimeout(() => {
          this.downloaded = false
        }, 5000)
      }
    }
  },
  methods: {
    async downloadSpreadsheet (versionId, versionTitle) {
      this.downloading = true
      this.$axios.get(`/admin/spreadsheet/${versionId}`, {
        responseType: 'blob'
      })
        .then(({ data: blob }) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `${versionTitle} - ${new Date().toISOString().slice(0, 10)}.xlsx`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
          this.downloading = false
          this.downloaded = true
        })
        .catch((err) => {
          alert('Error downloading spreadsheet')
          throw new Error(err)
        })
    },
    async loadResults () {
      const q = {}
      for (const key in this.query) {
        if (key) {
          // remove empty strings
          q[key] = this.query[key]
        }
      }
      this.fetchProjects(q)
    },
    async searchMyUser () {
      this.loading = true
      this.fetchProjects({
        'user._id': this.$store.state.userId
      })
    },
    async fetchProjects (params) {
      try {
        this.users = []
        this.loading = true
        const { data } = await this.$axios.get(`/admin/projects`, { params })
        if (data.projects.length === 0) {
          this.$store.dispatch('showSnackbar', 'No projects found with those parameters, check spelling and try again.')
        }
        this.projects = data.projects
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
