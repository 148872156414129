<template>
  <div class="sliced-overview-stats">
    <div
      v-if="!totalTrees"
      class="white d-flex align-center px-5 py-3 mb-4"
    >
      <v-skeleton-loader
        type="text"
        width="150"
        height="15"
        class="mr-5 mt-1"
      />
      <v-skeleton-loader
        type="chip"
        width="140"
      />
    </div>
    <div
      v-else
      class="white shamrock--text px-5 py-2 mb-4 d-flex align-center body-1"
    >
      Total trees planted:<span class="display-1 mx-3">{{ totalTrees.toLocaleString() }}</span>
    </div>
    <div class="white">
      <div class="title px-5 pt-5 pb-2">
        Stats overview
      </div>
      <div class="subtitle-2 px-5 pb-3">
        Excludes student projects and projects with onboarding data only
      </div>
      <v-select
        v-model="projectGrouping"
        label="Showing data for"
        class="mx-6 mt-2 mb-2"
        hide-details
        :items="[
          { text: 'All projects', value: 'All projects' },
          { text: 'Parks, residential, on-structure, mixed-use, campus developments', value: 'not(Streetscapes & plazas)' },
          { text: 'Streetscapes and plazas', value: 'Streetscapes & plazas' }
        ]"
      />
      <v-banner
        v-for="({ text, value, units, tooltipText }, index) in displayedStats"
        :key="index"
        class="px-0"
        style="height: 65px; box-sizing: border-box;"
        :style="{ 'border-left': `10px solid var(--v-${[
                    'metal',
                    'metal',
                    'accent',
                    'accent',
                    'north'
                  ][index]}-lighten1)`,
                  'margin-top': index === 0 ? '22px' : 0,
                  'background-color': (text === 'Mean YTP' && includedFirstVersion) ? '#E0E0E0' : 'white' }"
      >
        <v-skeleton-loader
          v-if="!value"
          type="text"
          height="14"
          style="margin: 9px 20px 9px 0;"
        />
        <v-tooltip
          v-else
          right
        >
          <template v-slot:activator="{ on }">
            <div
              style="width: fit-content"
              v-on="tooltipText ? on : undefined"
            >
              <span
                class="body-2"
                style="font-size: 0.8em !important;"
              >{{ text }}:</span>
              <span
                class="mx-2 px-0"
                :class="[
                  'metal--text',
                  'metal--text',
                  'accent--text',
                  'accent--text',
                  'shamrock--text',
                ][index]"
              >
                <span class="headline">{{ (text === 'Mean YTP' && includedFirstVersion) ? '-' : formatOverviewStatValue(value) }}</span>
                <span class="body-2"> {{ (text === 'Mean YTP' && includedFirstVersion) ? '' : units }}</span>
              </span>
            </div>
          </template>
          {{ tooltipText }}
        </v-tooltip>
      </v-banner>
      <!-- <div
        class="px-7 py-3 caption"
        style="height: 44px;"
      >
      Note: the values shown here are currently limited to the first six months of project data
      </div> -->
    </div>
  </div>
</template>

<script>
// import SlicedOverviewStatsLoader from './SlicedOverviewStatsLoader'

export default {
  name: 'SlicedOverviewStats',
  components: {
    // SlicedOverviewStatsLoader
  },
  props: {
    totalTrees: {
      type: Number,
      default: null
    },
    includedVersions: {
      type: Array,
      required: true
    },
    slicedOverviewStats: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      projectGrouping: 'All projects'
    }
  },
  computed: {
    displayedStats () {
      const ret = [
        { text: 'Median YTP', key: 'medianYtp', units: 'years' },
        { text: 'Mean YTP', key: 'meanYtp', units: 'years', tooltipText: this.includedFirstVersion ? 'Mean YTP figure unavailable for v1 projects' : null },
        { text: 'Area', key: 'totalAreaInSquareFeet', units: 'sq feet' },
        { text: 'Average CO₂e emitted per sq ft', key: 'averageCO2EmissionsInLbsPerSqFoot', units: 'lbs' },
        { text: 'Average CO₂e sequestered per sq ft', key: 'averageCO2SequestrationInLbsPerSqFoot', units: 'lbs' }
      ]
      if (!this.slicedOverviewStats) return ret
      return ret.map((item) => ({
        ...item,
        value: this.slicedOverviewStats[this.projectGrouping][item.key]
      }))
    },
    includedFirstVersion () {
      return this.includedVersions.includes('v1')
    }
  },
  methods: {
    formatOverviewStatValue (value) {
      value = Math.round(Math.abs(value) * 100) / 100
      if (value > 1000000000) {
        value = value / 1000000000
        return `${(Math.round(value * 10) / 10).toLocaleString()} billion`
      } else {
        return value.toLocaleString()
      }
    }
  }
}
</script>

<style lang="scss">
.sliced-overview-stats .v-banner__wrapper {
  padding-left: 1em;
}
</style>
