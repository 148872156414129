<template>
  <div class="kpi">
    <!-- <span class="kpi__title" :style="{ 'font-size': `${getProportion(1.2, 1.8, 30, 200, title.length)}em` }"> -->
    <span
      class="kpi__title"
      :style="{ 'font-size': titleFontSize }"
    >
      <slot name="title">
        {{ title }}
      </slot>
    </span>
    <span class="kpi__subtitle metal--text">
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </span>
    <span
      class="kpi__value"
      :class="`${valueColor}--text`"
    >
      <slot name="value">
        {{ displayedValue }}
      </slot>
    </span>
  </div>
</template>

<script>
const getProportion = (outputMin, outputMax, inputMin, inputMax, value) => {
  const outputVariance = outputMax - outputMin
  const inputVariance = inputMax - inputMin
  const multiplier = outputVariance / inputVariance
  const increment = inputVariance * multiplier
  return outputMin + increment
}

export default {
  name: 'KpiItem',
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    valueColor: {
      type: String,
      default: 'shamrock'
    }
  },
  data () {
    return {
      getProportion
    }
  },
  computed: {
    displayedValue () {
      if (!this.value) return this.value
      if (typeof this.value === 'string') {
        return this.value
      }
      return this.value.toLocaleString()
    },
    titleFontSize () {
      const len = this.title.length
      if (len > 40) {
        return '0.85em'
      } else {
        return '1em'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.kpi {
  // background: #f5fdf9;
  height: 100%;
  padding: 10px 20px 20px 20px;
  // border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .kpi__title {
    font-weight: 400;
    text-align: center;
  }
  .kpi__subtitle {
    font-size: 1em;
    text-align: center;
  }
  .kpi__value {
    font-size: 4.2em;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
  }
}
</style>
