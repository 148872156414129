<template>
  <v-btn
    class="mt-3"
    color="success"
    x-large
    elevation="0"
    rounded
    :loading="exporting"
    @click="handleClick"
  >
    <slot />
    <v-icon
      v-if="icon"
      right
      style="font-size: 26px;"
      class="ml-3 mr-1"
    >
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ExportButton',
  props: {
    url: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    extension: {
      type: String,
      default: 'csv'
    },
    name: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      exporting: false
    }
  },
  computed: {
    dateFormatted () {
      const d = new Date()
      return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`
    }
  },
  methods: {
    async handleClick () {
      this.exporting = true
      const res = await this.$axios.get(this.url, {
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.name}_${this.dateFormatted}.${this.extension}`)
      document.body.appendChild(link)
      link.click()
      this.exporting = false
      this.$store.dispatch('showSnackbar', {
        text: this.message,
        timeout: 5000
      })
    }
  }
}
</script>
