<template>
  <div
    id="cpd-dashboard"
    class="px-6"
    style="background: rgb(178, 226, 204); border-radius: 5px; overflow-y: auto; padding-bottom: 500px;"
  >
    <v-row class="align-end">
      <v-col>
        <div
          class="success--text"
          style="line-height: 1; font-weight: 200; font-size: calc(3vw + 30px) !important;"
        >
          Pathfinder Dashboard
        </div>
      </v-col>
      <Analytics :analytics="dashboardData.analytics" />
    </v-row>
    <v-alert
      v-if="isStaging"
      class="mt-4"
      color="onyx"
      dark
      icon="mdi-information-outline"
    >
      Staging data is displayed. Visit <a
        style="color: var(--v-metal-lighten2); font-weight: 500;"
        href="https://app.climatepositivedesign.com/admin"
        target="_blank"
      >app.climatepositivedesign.com/admin</a> for real-world analysis.
    </v-alert>
    <v-row>
      <v-col cols="auto">
        <FilterSettings
          :included-versions.sync="includedVersions"
          :include-all-contributors.sync="includeAllContributors"
        />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn
          class="mt-3 mr-3"
          color="success"
          x-large
          elevation="0"
          rounded
          href="https://docs.google.com/spreadsheets/d/1tRvdrH01zNUOopA9_8m81VygkAlK1o1Re0J4JleL4a0/edit#gid=0"
          target="_blank"
        >
          View v1 project data
          <v-icon
            right
            style="font-size: 26px;"
            class="ml-3 mr-1"
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
        <ExportButton
          class="mr-3"
          url="/admin/projects-to-csv"
          name="pathfinder_v2_projects"
          message="Latest v2 projects CSV downloaded to your device. All CO₂e values are in metric tonnes."
          icon="mdi-file-export"
        >
          Export latest v2 project data
        </ExportButton>
        <ExportButton
          url="/admin/users-to-csv"
          name="pathfinder_users_export"
          message="Latest users CSV downloaded to your device."
          icon="mdi-file-export"
        >
          Export latest user data
        </ExportButton>
      </v-col>
    </v-row>
    <FirstRow
      v-if="includedVersions.length > 0"
      :dashboard-data="dashboardData"
      :included-versions="includedVersions"
      :include-all-contributors="includeAllContributors"
    >
      <template #context-box-subtitle>
        <div class="d-flex">
          <v-checkbox
            v-model="includeAllContributors"
            inset
            color="shamrock"
            class="mt-0 mb-1"
            hide-details
          />
          Include student projects and projects with onboarding data?
        </div>
      </template>
    </FirstRow>
    <CertificationStats
      v-if="includedVersions.length > 0"
      :certification-stats="dashboardData.certificationStats"
      :v1-only="includedVersions.join(',') === 'v1'"
    />
    <v-row v-if="includedVersions.length > 0">
      <v-col cols="12">
        <ProjectOutputKpis :years="dashboardData.yearByYearStats" />
      </v-col>
    </v-row>
    <UserExplorer v-if="includedVersions.length > 0" />
  </div>
</template>

<script>
import FirstRow from './firstRow/FirstRow'
import UserExplorer from './UserExplorer'
import ProjectOutputKpis from './projectOutputKpis/ProjectOutputKpis'
import Analytics from './Analytics'
import CertificationStats from './CertificationStats'
import ExportButton from './ExportButton.vue'
import FilterSettings from './FilterSettings.vue'

const defaultDashboardData = {
  globalOverviewStats: {},
  slicedOverviewStats: null,
  certificationStats: null,
  yearByYearStats: []
}

export default {
  name: 'AdminDashboard',
  components: {
    UserExplorer,
    ProjectOutputKpis,
    FirstRow,
    CertificationStats,
    Analytics,
    ExportButton,
    FilterSettings
  },
  data () {
    return {
      dashboardData: defaultDashboardData,
      includedVersions: [],
      includeAllContributors: true,
      exportingProjectData: false,
      isStaging: false
    }
  },
  computed: {
    statsQuery () {
      return `versions=${this.includedVersions.join(',')}&includeAllContributors=${this.includeAllContributors}`
    }
  },
  watch: {
    statsQuery: {
      immediate: true,
      async handler () {
        if (this.includedVersions.length > 0) {
          this.loadStats()
        }
      }
    }
  },
  created () {
    this.isStaging = ['staging--climatepositivedesign.netlify.app', 'localhost'].includes(window.location.hostname)
    const defaultAppVersions = this.isStaging ? ['v2'] : ['v1', 'v2']
    this.includedVersions = JSON.parse(window.sessionStorage.getItem('pathfinder-admin-included-versions')) || defaultAppVersions
    this.includeAllContributors = JSON.parse(window.sessionStorage.getItem('pathfinder-admin-include-all-contributors')) || false
  },
  methods: {
    async loadStats () {
      this.dashboardData = defaultDashboardData
      window.sessionStorage.setItem('pathfinder-admin-included-versions', JSON.stringify(this.includedVersions))
      const { data } = await this.$axios.get(`/admin/stats?${this.statsQuery}`)
      this.dashboardData = data
    },
    async exportProjectData () {
      this.exportingProjectData = true
      const res = await this.$axios.get(`/admin/projects-to-csv`, {
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      const d = new Date()
      link.setAttribute('download', `pathfinder_v2_projects_${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}.csv`)
      document.body.appendChild(link)
      link.click()
      this.exportingProjectData = false
      this.$store.dispatch('showSnackbar', {
        text: 'Latest v2 projects CSV downloaded to your device. All CO₂e values are in metric tonnes.',
        timeout: 5000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.certification-stats-wrapper {
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.3);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
