<template>
  <v-row class="mb-5">
    <v-col
      v-for="(box, index) in boxes"
      :key="index"
      cols="12"
      sm="6"
      lg="4"
    >
      <ContextBox
        :icon="box.icon"
        :title="box.title"
        :subtitle="box.subtitle"
        :total="box.total"
        :kpis="box.kpis"
        :chart-options="box.chartOptions"
        :visible="!box.loading"
      >
        <template #subtitle>
          <slot
            v-if="box.useSlot"
            name="context-box-subtitle"
          />
        </template>
        <div
          v-if="box && !box.chartOptions"
          style="cursor: pointer;"
          @click="handleClick"
        >
          <div class="pointer-events: none !important;">
            <ImpactChart
              :years="dashboardData.yearByYearStats"
            />
          </div>
        </div>
      </ContextBox>
      <div
        v-if="box.notes"
        class="d-flex success--text caption mx-3 mt-5 font-weight-regular"
        style="height: 20px;"
      >
        {{ box.notes }}
      </div>
    </v-col>
    <v-col
      cols="12"
      lg="4"
    >
      <SlicedOverviewStats
        :key="includedVersions.length"
        :total-trees="dashboardData.globalOverviewStats.totalTrees"
        :sliced-overview-stats="dashboardData.slicedOverviewStats"
        :included-versions="includedVersions"
      />
    </v-col>
  </v-row>
</template>

<script>
import ContextBox from './ContextBox'
import ImpactChart from '@/components/atoms/ImpactChart'
import SlicedOverviewStats from './SlicedOverviewStats'
import { mapState } from 'vuex'

export default {
  name: 'FirstRow',
  components: {
    ContextBox,
    ImpactChart,
    SlicedOverviewStats
  },
  props: {
    dashboardData: {
      type: Object,
      required: true
    },
    includedVersions: {
      type: Array,
      required: true
    },
    includeAllContributors: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['colors']),
    boxes () {
      const { contributorStats, challengeStats, analytics } = this.dashboardData
      if (!analytics || !contributorStats || !challengeStats) {
        return [{ loading: true, kpis: new Array(3) }, { loading: true, kpis: new Array(2) }]
      }
      return [
        {
          icon: 'mdi-account',
          title: 'Contributors',
          useSlot: true,
          total: contributorStats.numberOfContributors,
          kpis: [
            { title: 'Companies', value: contributorStats.numberOfCompaniesWithProjects },
            { title: 'Countries', value: contributorStats.numberOfCountriesWithProjects },
            { title: 'Projects', value: contributorStats.numberOfProjects }
          ],
          // notes: '* Includes v1 and v2 app data. Includes student projects and projects with onboarding data only.',
          chartOptions: {
            type: 'doughnut',
            data: {
              labels: ['Online in last 7 days', 'Inactive contributors'],
              datasets: [{
                label: 'My First dataset',
                backgroundColor: [this.colors.shamrock, this.colors.leaf],
                // borderColor: 'rgb(255, 99, 132)',
                data: [analytics.dailyActiveUsers, contributorStats.numberOfContributors - analytics.dailyActiveUsers]
              }]
            }
          }
        },
        {
          icon: 'mdi-pine-tree',
          title: 'Projects',
          subtitle: 'Excludes student projects and projects with onboarding data only.',
          total: challengeStats.numberOfProjects,
          kpis: [
            { title: 'On target', value: challengeStats.numberOfProjectsOnTarget },
            {
              title: '% on target',
              value: typeof challengeStats.numberOfProjectsOnTarget === 'number' ? Math.round(challengeStats.numberOfProjectsOnTarget / challengeStats.numberOfProjects * 100) + '%' : 'N/A'
            }
          ]
        }
      ]
    }
  },
  methods: {
    handleClick () {
      window.document.getElementById('cpd-dashboard').scroll({
        top: 600,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style>

</style>
