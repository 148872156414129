<template>
  <v-col
    class="pt-2"
    cols="auto"
  >
    <v-card
      width="350"
      elevation="0"
    >
      <v-card-title class="pt-3 pb-0">
        <span>
          Website stats
          <!-- <span class="subtitle-1">(all time)</span> -->
        </span>
      </v-card-title>
      <v-card-text
        class="pt-1 pb-3"
        style="font-family: 'Roboto', sans-serif;"
      >
        <div
          v-if="!analytics"
          class="pt-2"
        >
          <div
            v-for="n in 3"
            :key="n"
            class="d-flex justify-space-between mb-1"
          >
            <v-skeleton-loader
              type="text"
              height="30"
              :width="80 + (30 * n)"
            />
            <v-skeleton-loader
              type="text"
              height="30"
              width="60"
            />
          </div>
          <v-skeleton-loader
            type="text"
            height="15"
            width="150"
            class="ml-auto"
          />
        </div>
        <div v-else>
          <div class="data-row">
            <strong>Total page views:</strong>
            <span>{{ analytics.totalPageViews.toLocaleString() }}</span>
          </div>
          <div class="data-row">
            <strong>Number of countries:</strong>
            <span>{{ analytics.numberOfCountries.toLocaleString() }}</span>
          </div>
          <div class="data-row">
            <strong>Total viewers (people reached):</strong>
            <span>{{ analytics.totalViewers.toLocaleString() }}</span>
          </div>
          <a
            class="d-block caption mt-2"
            href="https://analytics.google.com/analytics/web/?authuser=1#/report-home/a141614758w202702901p196456283"
            target="_blank"
            style="text-decoration: underline; text-align: right;"
          >
            Source: Google Analytics
          </a>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'AnalyticsStats',
  props: {
    analytics: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  strong {
    font-weight: 500;
    color: var(--v-onyx-base);
  }
  span {
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 1.75rem;
    letter-spacing: 0.009375em;
  }
}
</style>
