<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card
        elevation="0"
        class="mb-7 certification-stats-card"
      >
        <!-- <div
          v-if="v1Only"
          class="overlay title"
        >
          <div class="px-5 py-3 leaf">
            Not applicable to v1 app
          </div>
        </div> -->
        <v-card-title class="px-5 d-block pb-0">
          <div class="mb-2">
            Certification stats
            <span
              v-if="v1Only"
              class="body-2 ml-2 success--text"
            >
              * v2 app only
            </span>
          </div>
          <div class="subtitle-2 pb-1">
            Excludes student projects and projects with onboarding data only
          </div>
        </v-card-title>
        <v-card-text
          v-if="percentageWithCertification"
          class="px-5"
        >
          <v-row>
            <v-col
              cols="12"
              lg="6"
              xl="7"
            >
              <p>
                <span class="display-2">{{ percentageWithCertification }}%</span>
                <span class="headline">&nbsp;of projects are pursuing LEED and/or SITES certification.</span>
              </p>
              <p class="mb-2">
                That's {{ certificationStats.numberOfProjectsWithCertifications }}
                <span>project{{ certificationStats.numberOfProjectsWithCertifications > 1 ? 's' : '' }} out of the</span>
                {{ certificationStats.numberOfProjectsAskedAboutCertifications }}
                <span> created since starting to ask about certifications during project setup.</span>
              </p>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              xl="5"
            >
              <v-data-table
                :headers="tableHeaders"
                :items="[tableData]"
                :items-per-page="5"
                hide-default-footer
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CertificationStats',
  props: {
    certificationStats: {
      type: Object,
      default: () => {}
    },
    v1Only: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHeaders: [
        { text: 'LEED only', value: 'numberOfProjectsWithLEEDCertificationsOnly' },
        { text: 'SITES only', value: 'numberOfProjectsWithSITESCertificationsOnly' },
        { text: 'LEED and SITES', value: 'numberOfProjectsWithBothCertifications' },
        { text: 'LEED and/or SITES', value: 'numberOfProjectsWithCertifications' },
        { text: 'Total asked', value: 'numberOfProjectsAskedAboutCertifications' }
      ].map((x) => ({ ...x, sortable: false, selectable: false }))
    }
  },
  computed: {
    tableData () {
      return Object.fromEntries(Object.entries(this.certificationStats).map(([key, value]) => [key, `${value} projects`]))
    },
    percentageWithCertification () {
      if (!this.certificationStats) {
        return null
      }
      const val = this.certificationStats.numberOfProjectsWithCertifications / this.certificationStats.numberOfProjectsAskedAboutCertifications * 100
      return Math.round(val * 10) / 10
    }
  }
}
</script>

<style lang="scss">
.certification-stats-card {
  position: relative;
  .overlay {
    div {
      border-radius: 5px;
    }
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    background: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5) 8px,
      rgba(0, 0, 0, 0.6) 8px,
      rgba(0, 0, 0, 0.6) 16px
    );
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: transparent !important;
  }
}
</style>
